<!--
  * @Descripttion: 星球详情
  * @version:
  * @Author: GxG
  * @Date: 2023-03-16 11:11:11
  * @LastEditors: GxG
  * @LastEditTime: 2023-03-16 11:11:11
-->
<template>
  <div
    :class="[
      inOutAnimation
        ? 'stardetails-main-box fade-into-back'
        : 'stardetails-main-box fade-out-back',
      plantInfo.eraId ? 'eraId-type' + plantInfo.eraId : '',
      plantInfo.eraId ? 'bg' + plantInfo.eraId : '',
    ]"
  >
    <div class="stardetails-bg">
      <div
        class="stardetails-center"
        :class="
          inOutAnimation
            ? 'animate__animated animate__zoomIn'
            : 'animate__animated animate__zoomOut'
        "
        :style="{ height: lang == 'en_US' ? '591px' : '591px' }"
      >
        <!-- 关闭 -->
        <div class="stardetails-colse-box flex-y-center" @click="closeFun(0)">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="21.1822"
              height="1.24601"
              transform="matrix(0.701962 -0.712215 0.701962 0.712215 0.256226 15.0863)"
              fill="white"
            />
            <rect
              width="21.1822"
              height="1.24601"
              transform="matrix(0.701962 0.712215 -0.701962 0.712215 0.874634 0.0262756)"
              fill="white"
            />
          </svg>
        </div>
        <!-- 图片 -->
        <div
          :class="
            inOutAnimation
              ? 'stardetails-content-ce fade-into-img'
              : 'stardetails-content-ce fade-out-img'
          "
        >
          <img
            v-if="plantInfo.eraId == 6"
            src="../public/images/star/h5/ct-1.png"
          />
          <img
            v-if="plantInfo.eraId == 2"
            src="../public/images/star/h5/ct-2.png"
          />
          <img
            v-if="plantInfo.eraId == 3"
            src="../public/images/star/h5/ct-3.png"
          />
          <img
            v-if="plantInfo.eraId == 4"
            src="../public/images/star/h5/ct-4.png"
          />
          <img
            v-if="plantInfo.eraId == 5"
            src="../public/images/star/h5/ct-5.png"
          />
          <img
            v-if="plantInfo.eraId == 1"
            src="../public/images/star/h5/ct-6.png"
          />
          <img
            v-if="plantInfo.eraId == 7"
            src="../public/images/star/h5/ct-7.png"
          />
        </div>
        <!-- 内容 -->
        <div class="stardetails-content">
          <!-- 详情 -->
          <div
            :class="
              inOutAnimation
                ? 'stardetails-content-le fade-into-left'
                : 'stardetails-content-le fade-out-left'
            "
          >
            <div
              class="tardetails-content-le-title"
              :style="{ fontSize: lang == 'en_US' ? '30px' : '38px' }"
            >
              {{ pledgeInfo.eraName }}
            </div>
            <div class="tardetails-content-le-list-box w100 flex-x-start">
              <div
                class="tardetails-content-le-list-le"
                v-for="(item, index) in starDetailsList"
                :key="index"
              >
                <div class="tardetails-content-le-list-title">
                  {{ item.title }}
                </div>
                <div class="tardetails-content-le-list-texts">
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
          <!-- 赎回或质押 -->
          <div
            :class="
              inOutAnimation
                ? 'stardetails-content-ri fade-into-ri'
                : 'stardetails-content-ri fade-out-ri'
            "
          >
            <div class="poolinfo-title">{{ $t("pool.desc77") }}</div>
            <div
              class="poolinfo-user flex"
              :class="plantInfo.eraId ? 'eraBtn' + plantInfo.eraId : ''"
            >
              <div class="flexcenter">{{ $t("pool.desc78") }}</div>
              <p class="flexcenter" v-if="plantInfo.pageType == 'redmen'">
                {{ $t("pool.desc79") }} X {{ pledgeList.length }}
              </p>
              <p class="flexcenter" v-else>
                {{ $t("pool.desc100") }} X {{ pledgeList.length }}
              </p>
            </div>
            <div
              class="poolinfo-profit"
              :class="plantInfo.eraId ? 'eraBtn1' + plantInfo.eraId : ''"
            >
              <div class="profit-title">{{ $t("pool.desc80") }}</div>
              <div class="profit-info flex">
                <div class="flexcenter" v-if="plantInfo.pageType == 'redmen'">
                  X {{ planetInfo.outputAmountTotal }}
                </div>
                <div class="flexcenter" v-else>X 0</div>
                <img :src="pledgeInfo.outCoinIcon" alt="" />
              </div>
            </div>
            <div class="poolinfo-tips flex">
              <div class="tips-left">
                <div>
                  {{ $t("pool.desc81") }} {{ pledgeInfo.outCoinFullName }}
                </div>
                <p>{{ $t("pool.desc82") }}{{ pledgeInfo.outPutAvgRate }}%</p>
                <!-- <p>{{ $t('pool.desc83') }}1231</p> -->
              </div>
              <div class="tips-right">
                <div>APR</div>
                <p>{{ pledgeInfo.outPutAprRate }}%</p>
              </div>
            </div>
            <div
              class="poolinfo-btn flex"
              v-if="plantInfo.pageType == 'redmen'"
            >
              <div
                class="flexcenter animate__animated animate__bounceInUp"
                @click="handlerRevice(planetInfo.outputAmountTotal)"
              >
                {{ $t("pool.desc84") }}
              </div>

              <div
                class="flexcenter animate__animated animate__bounceInUp"
                v-if="pledgeList.length > 0"
                :style="{ paddingLeft: lang == 'en_US' ? '17px' : 0 }"
                @click="dialogVisible = true"
              >
                {{ $t("pool.desc85") }}
              </div>
              <div
                class="flexcenter animate__animated animate__bounceInUp"
                v-else
                :style="{ paddingLeft: lang == 'en_US' ? '17px' : 0 }"
              >
                {{ $t("pool.desc85") }}
              </div>
            </div>
            <div class="poolinfo-btn flex" v-else>
              <div
                class="flexcenter animate__animated animate__bounceInUp"
                @click="dialogPledge = true"
              >
                {{ $t("pool.desc95") }}
              </div>
            </div>
          </div>
        </div>

        <!-- 地球 -->
        <div
          :class="
            inOutAnimation
              ? 'plaent-box fade-into-star'
              : 'plaent-box fade-out-star'
          "
        ></div>
      </div>
    </div>
    <!-- 赎回 -->
    <el-dialog
      title="提示"
      v-model="dialogVisible"
      width="30%"
      :lock-scroll="true"
      :append-to-body="true"
      class="modalinfo"
      :modal="true"
    >
      <div class="modalinfo-con">
        <div class="modalinfo-con-title">
          {{ $t("pool.desc86") }}
          <img
            src="../public/images/pool/closemodal.png"
            alt=""
            @click="dialogVisible = false"
          />
        </div>
        <div class="backset-tips">{{ $t("pool.desc87") }}</div>
        <div class="pledge-list">
          <div
            class="pledge-list-con flex"
            v-for="(item, index) in pledgeList"
            :key="item.id"
          >
            <img :src="item.landIcon" alt="" class="pledge-img" />
            <div class="pledge-info flex">
              <div class="pledge-info-item">
                <div>{{ $t("planet.desc89") }}</div>
                <p>#{{ item.eraId }}-{{ item.landId }}</p>
              </div>
              <div class="pledge-info-item">
                <div>{{ $t("pool.desc99") }}</div>
                <p>{{ item.unSettleAmount }}</p>
              </div>
            </div>
            <div
              class="select-btn"
              :class="{ actpledge: item.ischeck }"
              @click="handlerImg(index)"
            >
              <img
                src="../public/images/selectbtn.png"
                alt=""
                v-if="item.ischeck"
              />
            </div>
          </div>
        </div>
        <div class="modalinfo-con-btn flex">
          <div class="flexcenter" @click="showRedeem(0)">
            {{ $t("pool.desc90") }}
          </div>
          <div class="flexcenter" @click="showRedeem(1)">
            {{ $t("pool.desc91") }}
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 质押 -->
    <el-dialog
      title="提示"
      v-model="dialogPledge"
      width="30%"
      :lock-scroll="true"
      :append-to-body="true"
      class="modalinfo"
      :modal="true"
    >
      <div class="modalinfo-con">
        <div class="modalinfo-con-title">
          {{ $t("pool.desc95") }}
          <img
            src="../public/images/pool/closemodal.png"
            alt=""
            @click="dialogPledge = false"
          />
        </div>
        <div class="backset-tips">{{ $t("pool.desc98") }}</div>
        <div class="pledge-list">
          <div
            class="pledge-list-con flex"
            v-for="(item, index) in pledgeList"
            :key="item.id"
          >
            <img :src="item.icon" alt="" class="pledge-img" />
            <div class="pledge-info flex">
              <div class="pledge-info-item">
                <div>{{ $t("planet.desc89") }}</div>
                <p>#{{ item.eraId }}-{{ item.landId }}</p>
              </div>
              <div class="pledge-info-item">
                <div>{{ $t("pool.desc88") }}</div>
                <p>{{ item.surplusSettleAmountDaot }} USDT</p>
              </div>
            </div>
            <div
              class="select-btn"
              :class="{ actpledge: item.ischeck }"
              @click="handlerImg(index)"
            >
              <img
                src="../public/images/selectbtn.png"
                alt=""
                v-if="item.ischeck"
              />
            </div>
          </div>
        </div>
        <div class="modalinfo-con-btn flex">
          <div class="flexcenter" @click="handlerSomePledge(0)">
            {{ $t("pool.desc96") }}
          </div>
          <div class="flexcenter" @click="handlerSomePledge(1)">
            {{ $t("pool.desc97") }}
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  toRefs,
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  watchEffect,
  computed,
} from "vue";
import i18n from "@/public/lang/i18n";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import common from "@/utils/common";
export default {
  name: "stardetails",
  props: ["id", "callback", "eraId", "pageType", "planetId"],
  setup(props) {
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const state = reactive({
      iconMoney: 0,
      planetInfo: {},
      showSuccess: false,
      dialogBackset: false,
      starDetailsList: null,
      googleCode: "",
      password: "",
      loading: false,
      massifImg: "", //土地图片
      massifnumber: "", //土地数量
      price: "", //单价
      priceSum: "", //总价
      isCustomPrice: false, //自定义价格
      inOutAnimation: true, //进入关闭动画
      dialogVisible: false,
      lang: "zh_CN",
      pledgeList: [],
      plantInfo: {},
      pledgeInfo: {},
      dialogPledge: false,
      isBtn: true,
    });
    // *********************************************************************************************************************************************************** //

    // 关闭按钮
    /**
     * @params {t} Number 自定义参数： t==0 关闭
     */
    const closeFun = function (t) {
      if (typeof t != "number") return;
      if (window.innerWidth > 1200) {
        //计算视口宽度是否在执行动画
        state.inOutAnimation = false;
        setTimeout(() => {
          props.callback(t);
        }, 800);
      } else {
        props.callback(t);
      }
    };

    // *********************************************************************************************************************************************************** //
    const dealInfo = function (item) {
      item.sales = parseFloat(item.totalLand) - parseFloat(item.saleCountLand);

      state.planetInfo = item;

      setTimeout(() => {
        state.massifImg = item.eraIcon;
      }, 50);
    };
    // **                                                                接口方法                                                                                ** //
    // *********************************************************************************************************************************************************** //

    // *********************************************************************************************************************************************************** //
    // **                                                              路由器执行逻辑                                                                             ** //
    // *********************************************************************************************************************************************************** //
    // *********************************************************************************************************************************************************** //
    // **                                                           stup执行完执行下列方法                                                                         ** //
    // *********************************************************************************************************************************************************** //
    const handlerImg = (i) => {
      state.pledgeList[i].ischeck
        ? (state.pledgeList[i].ischeck = false)
        : (state.pledgeList[i].ischeck = true);
    };
    const getStarList = function () {
      proxy.$post(proxy.URL.pool.usereralist, {}).then((res) => {
        if (state.plantInfo.eraId) {
          let array = res.data;
          for (let i = 0; i < array.length; i++) {
            if (array[i].eraId == state.plantInfo.eraId) {
              dealInfo(res.data[i]);
              break;
            }
          }
        }
      });
    };
    const handlerSomePledge = (i) => {
      let selectId = [],
        selectId1 = [];
      state.pledgeList.forEach((e) => {
        selectId1.push(e.landId);
        if (e.ischeck) {
          selectId.push(e.landId);
        }
      });
      if (i == 0 && selectId.length == 0) {
        ElMessage.error(i18n.global.t("pool.desc58"));
        return;
      }
      if (state.isBtn) {
        state.isBtn = false;
        proxy
          .$post(proxy.URL.pool.deposit, {
            poolId: 6,
            landIds: i == 0 ? selectId : selectId1,
          })
          .then((res) => {
            state.isBtn = true;
            if (res.code == 0) {
              ElMessage.success(i18n.global.t("pool.desc59"));
              state.dialogPledge = false;
              getinfo();
            } else if (res.code != 401) {
              ElMessage.error(res.message);
            }
          });
      }
    };
    const showRedeem = (i) => {
      let selectId = [],
        selectId1 = [];
      state.pledgeList.forEach((e) => {
        selectId1.push(e.recordId);
        if (e.ischeck) {
          selectId.push(e.recordId);
        }
      });
      if (i == 0 && selectId.length == 0) {
        ElMessage.error(i18n.global.t("pool.desc58"));
        return;
      }
      if (state.isBtn) {
        state.isBtn = false;
        proxy
          .$post(proxy.URL.pool.redeem, {
            recordIds: i == 0 ? selectId : selectId1,
          })
          .then((res) => {
            state.isBtn = true;
            if (res.code == 0) {
              ElMessage.success(i18n.global.t("pool.desc63"));
              state.dialogVisible = false;
              getinfo();
            } else if (res.code != 401) {
              ElMessage.error(res.message);
            }
          });
      }
    };
    const handlerRevice = (num) => {
      if (parseFloat(num) == 0) {
        return;
      }
      proxy
        .$post(proxy.URL.pool.outputReceive, {
          receivedType: 1,
          eraId: state.plantInfo.eraId,
        })
        .then((res) => {
          if (res.code == 0) {
            ElMessage.success(i18n.global.t("pool.desc65"));
            state.planetInfo.outputAmountTotal = 0;
          } else {
            ElMessage.error(res.message);
          }
        });
    };
    const getinfo = () => {
      proxy
        .$post(proxy.URL.pool.eraInfo, {
          eraId: props.eraId,
        })
        .then((res) => {
          state.pledgeInfo = res.data;
          state.starDetailsList = [
            {
              title: i18n.global.t("pool.desc14"),
              text: res.data.outCoinFullName,
            },
            {
              title: i18n.global.t("planet.desc12"),
              text: res.data.pledgingLandCount || 0,
            },
            {
              title: i18n.global.t("pool.desc105"),
              text: `${res.data.priceAmount} USDT` || "--",
            },
            {
              title: i18n.global.t("pool.desc106"),
              text: res.data.noChangeLandCount || 0,
            },
          ];
        });
      if (props.pageType == "pledge") {
        proxy
          .$post(proxy.URL.pool.ablepledge, {
            eraId: props.eraId,
          })
          .then((res) => {
            if (res.code == 0) {
              state.pledgeList = res.data;
              state.pledgeList.forEach((e) => {
                e.ischeck = false;
              });
            } else if (res.code != 401) {
              ElMessage.error(res.message);
            }
          });
      } else {
        proxy
          .$post(proxy.URL.pool.record, {
            eraId: props.eraId,
            page: 1,
            pageSize: 1000,
            status: 1,
          })
          .then((res) => {
            if (res.code == 0) {
              state.pledgeList = res.data.list;
              state.pledgeList.forEach((e) => {
                e.ischeck = false;
              });
            } else if (res.code != 401) {
              ElMessage.error(res.message);
            }
          });
      }
      getStarList();
    };
    onMounted(() => {
      state.plantInfo.id = props.id;
      state.plantInfo.eraId = props.eraId;
      state.plantInfo.pageType = props.pageType;
      state.plantInfo.planetId = props.planetId;
      state.lang = localStorage.getItem("language");
      getinfo();
    });

    return {
      closeFun,
      handlerImg,
      handlerSomePledge,
      showRedeem,
      handlerRevice,
      ...toRefs(state),
    };
  },
};
</script>
<style scoped lang="less">
@import "../views/home/global.css";

.poolinfo-title {
  margin-top: 65px;
  padding: 15px 0 8px;
  font-size: 24px;
  color: #ffffff;
  font-family: "Nebula";
}

.poolinfo-user {
  margin-bottom: 7px;
  padding: 9px 13px 18px 8px;
  background: url("../public/images/pool/textbg.png") center no-repeat;
  background-size: 100% 100%;

  div {
    flex: 1;
    font-size: 22px;
    color: #fff;
    justify-content: left;
  }

  p {
    flex: 0 0 164px;
    height: 56px;
    background: url("../public/images/pool/textbtn.png") center no-repeat;
    background-size: 100% 100%;
    font-size: 16px;
    color: #ffeba5;
  }
}
.eraBtn1 {
  p {
    background: url("../public/images/pool/textbtn4.png") center no-repeat;
    background-size: 100% 100%;
    color: #ba3dbd;
  }
}
.eraBtn2 {
  p {
    background: url("../public/images/pool/textbtn6.png") center no-repeat;
    background-size: 100% 100%;
    color: #5dcd21;
  }
}
.eraBtn4 {
  p {
    background: url("../public/images/pool/textbtn8.png") center no-repeat;
    background-size: 100% 100%;
    color: #ff7b00;
  }
}
.eraBtn5 {
  p {
    background: url("../public/images/pool/textbtn10.png") center no-repeat;
    background-size: 100% 100%;
    color: #096ffd;
  }
}
.eraBtn7 {
  p {
    background: url("../public/images/pool/textbtn12.png") center no-repeat;
    background-size: 100% 100%;
    color: #04779c;
  }
}
.poolinfo-profit {
  margin-bottom: 24px;
  background: url("../public/images/pool/textbg1.png") center no-repeat;
  background-size: 100% 100%;

  .profit-title {
    height: 26px;
    line-height: 26px;
    font-size: 16px;
    color: #ffffff;
  }

  .profit-info {
    padding: 13px;

    div {
      flex: 0 0 164px;
      height: 56px;
      background: url("../public/images/pool/textbtn1.png") center no-repeat;
      background-size: 100% 100%;
      font-size: 20px;
      color: #ffeba5;
    }

    img {
      flex: 0 0 136px;
      width: 136px;
      height: 56px;
      margin-left: 33px;
      object-fit: cover;
    }
  }
}

.eraBtn11 {
  .profit-info {
    div {
      background: url("../public/images/pool/textbtn5.png") center no-repeat;
      background-size: 100% 100%;
      color: #ba3dbd;
    }
  }
}
.eraBtn12 {
  .profit-info {
    div {
      background: url("../public/images/pool/textbtn7.png") center no-repeat;
      background-size: 100% 100%;
      color: #5dcd21;
    }
  }
}
.eraBtn14 {
  .profit-info {
    div {
      background: url("../public/images/pool/textbtn9.png") center no-repeat;
      background-size: 100% 100%;
      color: #ff7b00;
    }
  }
}
.eraBtn15 {
  .profit-info {
    div {
      background: url("../public/images/pool/textbtn11.png") center no-repeat;
      background-size: 100% 100%;
      color: #096ffd;
    }
  }
}
.eraBtn17 {
  .profit-info {
    div {
      background: url("../public/images/pool/textbtn13.png") center no-repeat;
      background-size: 100% 100%;
      color: #04779c;
    }
  }
}
.poolinfo-tips {
  .tips-left {
    flex: 1;
    background: rgba(17, 0, 0, 0.16);
    color: #fff;

    div {
      padding: 30px 0 0 19px;
      font-size: 14px;
      line-height: 23px;
    }

    p {
      margin-top: 2px;
      padding-left: 19px;
      font-size: 13px;
      line-height: 17px;
    }
  }

  .tips-right {
    flex: 0 0 142px;
    height: 107px;
    margin-left: 6px;
    background: rgba(17, 0, 0, 0.16);
    color: #fff;
    text-align: right;

    div {
      margin-right: 16px;
      padding-top: 20px;
      font-size: 18px;
    }

    p {
      margin-right: 16px;
      font-size: 35px;
      font-weight: 600;
    }
  }
}

.poolinfo-btn {
  position: absolute;
  bottom: -3px;
  right: -31px;
  width: 100%;
  justify-content: flex-end;

  div {
    flex: 0 0 189px;
    height: 45px;
    background: url("../public/images/pool/textbtn2.png") center no-repeat;
    background-size: 100%;
    font-size: 18px;
    color: #ffeba5;
    font-family: "Nebula";
    cursor: pointer;

    &:last-child {
      flex: 0 0 188px;
      height: 47px;
      background: url("../public/images/pool/textbtn3.png") center no-repeat;
      background-size: 100%;
      color: #000000;
    }
  }
}

.stardetails-main-box {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 96px;
  left: 0;
  z-index: 88;
  background: #000000;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0px; //修改滚动条宽度
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #666666;
  }
}

.stardetails-bg {
  width: 100%;
  padding-bottom: 120px;
  padding-top: 21px;
  background-color: #000;
  background-image: url("../public/images/star/bg-6.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  align-items: flex-start;
  /* filter: blur(200px); */
}

.eraId-type2 .stardetails-bg {
  background-image: url("../public/images/star/bg-2.png");
}

.eraId-type3 .stardetails-bg {
  background-image: url("../public/images/star/bg-3.png");
}

.eraId-type4 .stardetails-bg {
  background-image: url("../public/images/star/bg-4.png");
}

.eraId-type5 .stardetails-bg {
  background-image: url("../public/images/star/bg-5.png");
}

.eraId-type6 .stardetails-bg {
  background-image: url("../public/images/star/bg-1.png");
}

.eraId-type7 .stardetails-bg {
  background-image: url("../public/images/star/bg-7.png");
}

.stardetails-center {
  width: 1293px;
  margin: 0 auto;
  position: relative;
  background-image: url("../public/images/star/ct-6.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.eraId-type2 .stardetails-center {
  background-image: url("../public/images/star/ct-2.png");
}

.eraId-type3 .stardetails-center {
  background-image: url("../public/images/star/ct-3.png");
}

.eraId-type4 .stardetails-center {
  background-image: url("../public/images/star/ct-4.png");
}

.eraId-type5 .stardetails-center {
  background-image: url("../public/images/star/ct-5.png");
}

.eraId-type6 .stardetails-center {
  background-image: url("../public/images/star/ct-1.png");
}

.eraId-type7 .stardetails-center {
  background-image: url("../public/images/star/ct-7.png");
}

.stardetails-colse-box {
  position: absolute;
  top: 18px;
  left: 0;
  width: 44px;
  height: 44px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  cursor: pointer;
}

.stardetails-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.stardetails-content-le {
  margin-top: 100px;
  width: 368px;
  min-width: 368px;
}

.tardetails-content-le-title {
  font-family: "Nebula";
  font-style: normal;
  font-weight: 400;
  font-size: 38px;
  line-height: 44px;
  letter-spacing: -2px;
  text-transform: capitalize;
  color: #ffffff;
  margin-bottom: 40px;
}

.tardetails-content-le-list-box {
  flex-wrap: wrap;
  padding: 40px 17px;
}

.tardetails-content-le-list-le {
  margin-bottom: 24px;
  overflow: hidden;
}

.tardetails-content-le-list-le:nth-child(even) {
  width: 100px;
}

.tardetails-content-le-list-le:nth-child(odd) {
  width: 166px;
}

.tardetails-content-le-list-title {
  font-family: "Bai Jamjuree";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: rgba(255, 255, 255, 0.8);
}

.tardetails-content-le-list-texts {
  font-family: "Bai Jamjuree";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  text-transform: capitalize;
  color: #ffffff;
  margin-top: 10px;
}

.stardetails-content-ce {
  display: none;
  width: 100%;
}

.stardetails-content-ce > img {
  width: 100%;
  height: auto;
  display: block;
}

.stardetails-content-ri {
  position: relative;
  width: 354px;
  height: 536px;
  min-width: 354px;
}

.stardetails-content-ri-title {
  font-family: "Nebula";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  text-transform: capitalize;
  color: #ffffff;
}

.stardetails-content-ri-list-box {
  margin-top: 20px;
  width: 100%;
  flex-wrap: wrap;
}

.stardetails-content-ri-list:nth-child(odd) {
  margin-right: 16px;
}

.stardetails-content-ri-list {
  min-width: 164px;
  height: 56px;
  font-family: "Bai Jamjuree";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 61px;
  text-align: center;
  text-transform: capitalize;
  color: #847a51;
  overflow: hidden;
  margin-bottom: 11px;
  cursor: pointer;
  background-image: url("../public/images/star/num-6.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.eraId-type2 .stardetails-content-ri-list {
  color: #4d892d;
  background-image: url("../public/images/star/num-2.png");
}

.eraId-type3 .stardetails-content-ri-list {
  color: #9d9370;
  background-image: url("../public/images/star/num-3.png");
}

.eraId-type4 .stardetails-content-ri-list {
  color: #ba6b21;
  background-image: url("../public/images/star/num-4.png");
}

.eraId-type5 .stardetails-content-ri-list {
  color: #2b61ac;
  background-image: url("../public/images/star/num-5.png");
}

.eraId-type6 .stardetails-content-ri-list {
  color: #847a51;
  background-image: url("../public/images/star/num-1.png");
}

.eraId-type7 .stardetails-content-ri-list {
  color: #04779c;
  background-image: url("../public/images/star/num-7.png");
}

.stardetails-content-ri-list-acetive {
  color: #ffe279;
  background-image: url("../public/images/star/num-on-6.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.eraId-type2 .stardetails-content-ri-list-acetive {
  color: #5dcd21;
  background-image: url("../public/images/star/num-on-2.png");
}

.eraId-type3 .stardetails-content-ri-list-acetive {
  color: #ffeba5;
  background-image: url("../public/images/star/num-on-3.png");
}

.eraId-type4 .stardetails-content-ri-list-acetive {
  color: #ff7b00;
  background-image: url("../public/images/star/num-on-4.png");
}

.eraId-type5 .stardetails-content-ri-list-acetive {
  color: #096ffd;
  background-image: url("../public/images/star/num-on-5.png");
}

.eraId-type6 .stardetails-content-ri-list-acetive {
  color: #ba3dbd;
  background-image: url("../public/images/star/num-on-1.png");
}

.eraId-type7 .stardetails-content-ri-list-acetive {
  color: #00c2ff;
  background-image: url("../public/images/star/num-on-7.png");
}

.stardetails-content-ri-price {
  margin-top: 24px;
  width: 100%;
  height: 107px;
  background: rgba(17, 0, 0, 0.16);
  // backdrop-filter: blur(4px);
  padding: 24px;
}

.stardetails-tips {
  padding: 9px 0;
  font-size: 12px;
  color: #ffffff;
}

.el-dropdown {
  border: 0 !important;
}

.el-dropdown-link {
  width: 345px;
  padding: 12px 0;
  background: rgba(0, 0, 0, 0.2);
  border: 0 !important;

  .icon-name {
    position: relative;
    padding: 0 16px;
    font-size: 16px;
    color: #fff;
    line-height: 24px;
    cursor: pointer;

    img {
      flex: 0 0 24px;
      width: 24px;
      height: 24px;
      margin-right: 8px;
      border-radius: 50%;
      object-fit: cover;
    }

    p {
      position: absolute;
      top: 0;
      right: 34px;
      font-size: 16px;
      color: #fff;
      line-height: 24px;
    }

    &::after {
      content: "";
      position: absolute;
      top: 9px;
      right: 16px;
      width: 8px;
      height: 6px;
      background: url("../public/images/loginicon1.png") center no-repeat;
      background-size: 100% 100%;
    }
  }
}

.el-dropdown-menu {
  width: 345px;
  background-color: #4c4426 !important;
}

.stardetails-price {
  margin-top: 9px;
  justify-content: space-between;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);

  span {
    color: #fff;
  }
}

.coin-info {
  position: relative;
  display: flex;
  font-size: 16px;
  color: #fff;
  line-height: 24px;
  cursor: pointer;
  width: 100%;

  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 8px;
    object-fit: cover;
  }

  p {
    position: absolute;
    top: 0;
    right: 16px;
    font-size: 16px;
    color: #bbbbbb;
  }
}

// .icon-name{
//     width: 345px;
//     padding: 12px 0;
//     background: rgba(0, 0, 0, 0.4);
// }

.stardetails-content-ri-price-le-title {
  font-family: "Bai Jamjuree";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: rgba(255, 255, 255, 0.8);
}

.stardetails-content-ri-price-le-calc {
  margin-top: 14px;
}

.stardetails-content-ri-price-sub,
.stardetails-content-ri-price-add {
  border: 1px solid #555555;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.price rect {
  opacity: 1;
}

.stardetails-content-ri-price-viw {
  border-top: 1px solid #555555;
  border-bottom: 1px solid #555555;
  width: 50px;
  height: 30px;
  font-family: "Bai Jamjuree";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
}

.stardetails-content-ri-price-viw input {
  font-size: 16px;
  color: #fff;
  width: 48px;
  height: 28px;
  text-align: center;
}

.stardetails-content-ri-price-ce {
  width: 1px;
  height: 44px;
  background: rgba(255, 255, 255, 0.2);
  margin: 0 17px 0 32px;
}

.stardetails-content-ri-price-ri {
  flex: 1;
}

.stardetails-content-ri-price-ri-sum {
  height: 30px;
  font-family: "Bai Jamjuree";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
  color: #ffffff;
  margin-top: 14px;
}

.stardetails-content-ri-but {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 48px;
  width: 188px;
  height: 48px;
  background-image: url("../public/images/star/bt-1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  font-family: "Nebula";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  text-transform: capitalize;
  color: #000000;
}

.eraId-type2 .stardetails-content-ri-but {
  background-image: url("../public/images/star/bt-2.png");
}

.eraId-type3 .stardetails-content-ri-but {
  background-image: url("../public/images/star/bt-3.png");
}

.eraId-type4 .stardetails-content-ri-but {
  background-image: url("../public/images/star/bt-4.png");
}

.eraId-type5 .stardetails-content-ri-but {
  background-image: url("../public/images/star/bt-5.png");
}

.eraId-type6 .stardetails-content-ri-but {
  background-image: url("../public/images/star/bt-6.png");
}

.eraId-type7 .stardetails-content-ri-but {
  background-image: url("../public/images/star/bt-7.png");
}

.stardetails-content-ri-but-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: "Nebula";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 50px;
  text-align: center;
  text-transform: capitalize;
  color: #000000;
}

.plaent-box {
  position: absolute;
  top: 52px;
  left: 50%;
  transform: translateX(-50%);
  width: 106px;
  height: 112px;
  background-image: url("../public/images/star/yang-6.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.eraId-type2 .plaent-box {
  background-image: url("../public/images/star/yang-2.png");
}

.eraId-type3 .plaent-box {
  background-image: url("../public/images/star/yang-3.png");
}

.eraId-type4 .plaent-box {
  background-image: url("../public/images/star/yang-4.png");
}

.eraId-type5 .plaent-box {
  background-image: url("../public/images/star/yang-5.png");
}

.eraId-type6 .plaent-box {
  background-image: url("../public/images/star/yang-1.png");
}

.eraId-type7 .plaent-box {
  background-image: url("../public/images/star/yang-7.png");
}

/* css动画 */
@media (min-width: 1200px) {
  /* 1\css 动画从左侧进入 */
  .fade-into-left {
    animation: fade-into-left 1s forwards;
  }

  @keyframes fade-into-left {
    from {
      opacity: 0;
      transform: translateX(-50px);
    }

    to {
      opacity: 1;
      transform: translateX(21.5px);
    }
  }

  /* 1\css 动画从左侧出去 */
  .fade-out-left {
    animation: fade-out-left 1s forwards;
  }

  @keyframes fade-out-left {
    from {
      opacity: 1;
      transform: translateX(0px);
    }

    to {
      opacity: 0;
      transform: translateX(-50px);
    }
  }

  /* 2\css 动画从右侧进入 */
  .fade-into-ri {
    animation: fade-into-ri 1s forwards;
  }

  @keyframes fade-into-ri {
    from {
      opacity: 0;
      transform: translateX(50px);
    }

    to {
      opacity: 1;
      transform: translateX(-32px);
    }
  }

  /* 2\css 动画从右侧出去 */
  .fade-out-ri {
    animation: fade-out-ri 1s forwards;
  }

  @keyframes fade-out-ri {
    from {
      opacity: 1;
      transform: translateX(0px);
    }

    to {
      opacity: 0;
      transform: translateX(50px);
    }
  }

  /* 3\css 中间img 进入动画 */
  .fade-into-img {
    animation: fade-into-img 1s forwards;
  }

  @keyframes fade-into-img {
    from {
      opacity: 0;
      transform: scale(0);
    }

    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  /* 3\css 中间img 出去动画 */
  .fade-out-img {
    animation: fade-out-img 1s forwards;
  }

  @keyframes fade-out-img {
    from {
      opacity: 1;
      transform: scale(1);
    }

    to {
      opacity: 0;
      transform: scale(0);
    }
  }

  /* 4\css 顶部星球 进入动画 */
  .fade-into-star {
    animation: fade-into-star 1s forwards;
  }

  @keyframes fade-into-star {
    from {
      opacity: 0;
      top: -100px;
      left: 155px;
      transform: scale(0);
    }

    to {
      opacity: 1;
      top: 52px;
      left: 555px;
      transform: scale(1);
    }
  }

  /* 4\css 顶部星球 出去动画 */
  .fade-out-star {
    animation: fade-out-star 1s forwards;
  }

  @keyframes fade-out-star {
    from {
      opacity: 1;
      top: 0;
      left: 275px;
      transform: scale(1);
    }

    to {
      opacity: 0;
      top: 200px;
      left: 155px;
      transform: scale(0);
    }
  }

  /* 5\css 背景 进入动画 */
  .fade-into-back {
    animation: fade-into-back 0.8s forwards;
  }

  @keyframes fade-into-back {
    from {
      opacity: 0;
      /* transform: scale(0); */
    }

    to {
      opacity: 1;
      /* transform: scale(1); */
    }
  }

  /* 5\css 背景 出去动画 */
  .fade-out-back {
    animation: fade-out-back 0.8s forwards;
  }

  @keyframes fade-out-back {
    from {
      opacity: 1;
      /* transform: scale(1); */
    }

    to {
      opacity: 0;
      /* transform: scale(0); */
    }
  }
}

/* 适配 */
@media (max-width: 1200px) {
  .poolinfo-title {
    margin-top: 0;
    font-size: 16px;
  }
  .poolinfo-user {
    div {
      font-size: 20px;
    }
  }
  .poolinfo-btn {
    position: inherit;
    top: 0;
    right: 0;
    margin-top: 19px;
    flex-wrap: wrap;
    justify-content: inherit;
    div {
      flex: 0 0 100%;
      height: 56px;
      background: url("../public/images/pool/smallbg.png") center no-repeat;
      background-size: 100% 100%;
      color: #111111;
      font-size: 20px;
      &:last-child {
        flex: 0 0 100%;
        margin-top: 18px;
        background: url("../public/images/pool/smallbg.png") center no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .poolinfo-tips {
    justify-content: space-between;
    .tips-left {
      flex: 0 0 48%;
      div,
      p {
        font-size: 13px;
      }
    }
    .tips-right {
      flex: 0 0 48%;
      margin: 0;
      div {
        font-size: 18px;
      }
      p {
        font-size: 35px;
      }
    }
  }
  .stardetails-main-box {
    overflow-y: auto;
    top: 0;
    padding-top: 0;
    z-index: 9999;
  }

  .stardetails-bg {
    width: auto;
    height: auto;
    padding-top: 0;
  }

  .eraId-type1 .stardetails-bg,
  .bg1 {
    background: url("../public/images/star/h5/bg-6.png");
    background-size: 100% 100%;
  }

  .eraId-type2 .stardetails-bg,
  .bg2 {
    background-image: url("../public/images/star/h5/bg-2.png");
    background-size: 100% 100%;
  }

  .eraId-type3 .stardetails-bg,
  .bg3 {
    background-image: url("../public/images/star/h5/bg-3.png");
    background-size: 100% 100%;
  }

  .eraId-type4 .stardetails-bg,
  .bg4 {
    background-image: url("../public/images/star/h5/bg-4.png");
    background-size: 100% 100%;
  }

  .eraId-type5 .stardetails-bg,
  .bg5 {
    background-image: url("../public/images/star/h5/bg-5.png");
    background-size: 100% 100%;
  }

  .eraId-type6 .stardetails-bg,
  .bg6 {
    background-image: url("../public/images/star/h5/bg-1.png");
    background-size: 100% 100%;
  }

  .eraId-type7 .stardetails-bg,
  .bg7 {
    background-image: url("../public/images/star/h5/bg-7.png");
    background-size: 100% 100%;
  }

  .stardetails-center {
    width: 100%;
    margin: 0;
    margin-top: 24px;
    height: auto;
    background: transparent !important;
  }

  .stardetails-colse-box {
    top: 26px;
    right: 16px;
    width: 44px;
    height: 44px;
    margin-left: auto;
  }

  .plaent-box {
    top: 19px;
    left: 16px;
    width: 62px;
    height: 62px;
    transform: none;
  }

  .stardetails-content {
    display: block;
    height: auto;
    padding: 0 16px;
  }

  .stardetails-content-ce {
    display: block;
    padding-bottom: 100%;
    height: 0;
  }

  .stardetails-content-le {
    width: 100%;
    margin-top: 0;
    min-width: 100%;
  }

  .tardetails-content-le-title {
    font-size: 24px;
    margin-bottom: 26px;
  }

  .tardetails-content-le-list-box {
    padding: 0;
    margin-bottom: 16px;
  }

  .tardetails-content-le-list-texts {
    font-size: 20px;
  }

  .tardetails-content-le-list-le:nth-child(even) {
    width: 33.33%;
  }

  .tardetails-content-le-list-le:nth-child(odd) {
    width: 33.33%;
  }

  .stardetails-content-ri {
    margin-top: 0;
    min-width: 100%;
  }

  .stardetails-content-ri {
    width: 100%;
  }

  .stardetails-content-ri-title {
    font-size: 16px;
    margin-bottom: 16px;
  }

  .stardetails-content-ri-list-box {
    margin-top: 0;
    justify-content: space-between;
  }

  .stardetails-content-ri-list {
    width: 164px;
    height: 56px;
    margin: 0 0 8px 0;

    &:nth-child(odd) {
      margin-right: 0;
    }
  }

  .stardetails-content-ri-price {
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 0;
    background: rgba(110, 110, 110, 0.16);
  }

  .stardetails-center .stardetails-content-ri-but {
    margin: 46px auto 16px;
    position: inherit;
    bottom: inherit;
    background-image: url("../public/images/star/h5/bt.png");
    width: 345px;
    height: 54px;
  }
}

@media (max-width: 767px) {
  .mining {
    width: auto;
  }

  .buy-success {
    width: 90%;
  }

  .el-dropdown {
    width: calc(100vw - 8px);
  }

  .stardetails-price {
    margin-bottom: 10px;
  }

  .el-dropdown-link {
    margin-top: 10px;
    width: 100% !important;
  }

  .el-dropdown-menu {
    width: calc(100vw - 8px) !important;
  }
}
</style>

<template>
    <div class="pool">
        <div class="pool-content">
            <div class="title">{{ $t('pool.desc7') }}</div>
            <div class="pc-wrap">
                <div class="pc-item" :class="poolClassList[i]" v-for="(item, i) in poolList" :key="i"
                    @click="exchangeBox(i)">
                    <div class="tips-num">
                        <div class="name">
                            {{ item.name }}
                            <span>X{{ item.landCount || 0 }}</span>
                        </div>
                    </div>
                    <img :src="item.icon" alt="">
                    <div class="tips-btn flex">
                        <div @click="handlerPledge(item)">{{ $t('pool.desc76') }}</div>
                        <div @click="handlerRedem(item)">{{ $t('pool.desc75') }}</div>
                    </div>
                </div>
            </div>
            <div class="pc-page">
                <div class="pc-page-item" :class="{ 'on': poolClassIndex == i }" v-for="(item, i) in 7" :key="i"></div>
            </div>
        </div>
        <div class="pool-content-h5">
            <div class="pch-in">
                <div class="title">{{ $t('pool.desc7') }}</div>
                <van-swipe :autoplay="3000" lazy-render @change="onChange">
                    <van-swipe-item v-for="(item, i) in poolList" :key="i">
                        <img :src="item.icon" />
                    </van-swipe-item>
                </van-swipe>
            </div>
            <div class="h5-btn flex">
                <div class="flexcenter" @click="handlerPledge(swiperInfo)">{{ $t('pool.desc76') }}</div>
                <div class="flexcenter" @click="handlerRedem(swiperInfo)">{{ $t('pool.desc75') }}</div>
            </div>
        </div>

        <div class="pool-statistics flex">
            <div class="statistics-left">
                <div class="left-title flex">
                    <img src="../../public/images/daiicon.png" alt="" />{{ $t('pool.desc') }}
                </div>
                <div class="left-total">{{ common.getnum(poolinfo.rewardTotalValUsdt) || '0.00' }} USDT</div>
                <div class="left-info flex">
                    <div class="left-info-name">
                        <div>{{ $t('pool.desc1') }}</div>
                        <p>{{ common.getnum(poolinfo.pledgingValUsdt) || '0.00' }} USDT</p>
                    </div>
                    <div class="left-info-name">
                        <div>{{ $t('pool.desc2') }}</div>
                        <p>{{ poolinfo.pledgingCount }}</p>
                    </div>
                </div>
            </div>
            <div class="statistics-draw-out">
                <div class="statistics-draw" v-for="item in poolinfo.incomeList">
                    <div class="draw-title">{{ item.incomeName }}</div>
                    <div class="draw-price">{{ common.getnum(item.incomeAmount) || '0.00' }}</div>
                    <div class="draw-btn flexcenter" :class="{ grey: parseFloat(item.incomeAmount) == 0 }"
                        @click="handlerReceive(item.incomeType, item.incomeAmount)">{{
                            $t('pool.desc6') }}</div>
                </div>
                <!-- <div class="statistics-draw">
                    <div class="draw-title">{{ $t('pool.desc4') }}</div>
                    <div class="draw-price">{{   common.getnum(poolinfo.recRewardDc) || '0.00'}}</div>
                    <div class="draw-btn flexcenter" :class="{ grey: parseFloat(poolinfo.recRewardDc) == 0 }"
                        @click="handlerReceive(1, poolinfo.recRewardDc)">{{ $t('pool.desc6')
                        }}</div>
                </div>
                <div class="statistics-draw">
                    <div class="draw-title">{{ $t('pool.desc5') }}</div>
                    <div class="draw-price">{{  common.getnum(poolinfo.communityRewardDc) || '0.00'}}</div>
                    <div class="draw-btn flexcenter" :class="{ grey: parseFloat(poolinfo.communityRewardDc) == 0 }"
                        @click="handlerReceive(2, poolinfo.communityRewardDc)">{{
                            $t('pool.desc6') }}</div>
                </div> -->
            </div>
        </div>
        <!-- 时代详情 -->
        <poolInfo v-if="isPoolInfo" :id="starDetailsId" :eraId="eraId" :pageType="pageType" :callback="closeFun">
        </poolInfo>
        <!-- 购买 -->
        <starDetails v-if="isStarDetails" :id="starDetailsId" :eraId="eraId" :pageType="pageType" :callback="closeFun">
        </starDetails>
        <div id="footer">
            <glFooter :act="2" />
        </div>
    </div>
</template>

<script>
import poolInfo from "../../componets/poolinfo.vue";
import starDetails from "../../componets/starDetails.vue";
import glFooter from '@/componets/footer.vue'
export default {
    components: {
        starDetails,
        glFooter,
        poolInfo
    },
    data() {
        return {
            isStarDetails: false,
            starDetailsId: '',
            isPoolInfo: false,
            eraId: '',
            pageType: 'mining',
            poolinfo: {},
            page: 1,
            list: [],
            total: 0,
            poolList: [],
            poolClassList: [
                'little-box',
                'left-small-box',
                'big-box',
                'right-small-box',
                'other-box',
                'other-box',
                'other-box',
            ],
            poolClassIndex: 2,
            pledgeNum: 10,
            swiperInfo: {}
        }
    },
    created() {
        this.getreceive()
        this.getPoolList()
        // this.getlist();

    },
    methods: {
        onChange(index) {

            this.swiperInfo = this.poolList[index]
        },
        handlerRedem(info) {
            if (info.landCount == 0 && info.pledgingLandCount == 0) {
                this.pageType = 'buy'
                this.eraId = info.id;
                this.isStarDetails = true;
                return;
            }
            if (info.landCount > 0 && info.pledgingLandCount == 0) {
                this.pageType = 'pledge'
                this.eraId = info.id;
                this.isPoolInfo = true;
                return;
            }
            this.pageType = 'redmen'
            this.eraId = info.id;
            this.isPoolInfo = true;
        },
        handlerPledge(info) {

            this.$post(this.URL.planet.myland, {
                eraId: info.id,
                status: 0,
                pageSize: 10,
                page: 1
            }).then(res => {
                if (res.code == 0) {
                    if (res.data.list.length > 0) {
                        this.pageType = 'pledge';
                        this.eraId = info.id;
                        this.isPoolInfo = true;
                    } else {
                        this.pageType = 'buy'
                        this.eraId = info.id;
                        this.isStarDetails = true;
                    }
                }
            })
        },
        closeFun() {
            this.isStarDetails = false;
            this.isPoolInfo = false;
            this.getPoolList()
        },
        exchangeBox(i) {
            if (i == this.poolClassIndex) {

            } else {
                let arr = []
                if (i == 6 && this.poolClassIndex == 0) {
                    arr = this.poolClassList.slice(1, 7)
                    arr.push(this.poolClassList[0])
                } else if (i == 0 && this.poolClassIndex == 6) {
                    arr = this.poolClassList.slice(0, 6)
                    arr.unshift(this.poolClassList[6])
                } else if (i > this.poolClassIndex) {
                    arr = this.poolClassList.slice(0, 6)
                    arr.unshift(this.poolClassList[6])
                } else if (i < this.poolClassIndex) {
                    arr = this.poolClassList.slice(1, 7)
                    arr.push(this.poolClassList[0])
                }
                this.poolClassList = arr
                this.poolClassIndex = i
            }
        },
        getPoolList() {
            this.$post(this.URL.pool.eralist, {}).then(res => {
                if (res.code == 0) {
                    this.poolList = res.data;
                    this.swiperInfo = this.poolList[0]
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        getreceive() {
            this.$post(this.URL.pool.overview, {}).then(res => {
                if (res.code == 0) {
                    this.poolinfo = res.data;
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        handlerReceive(i, num) {
            if (parseFloat(num) > 0) {
                this.$post(this.URL.pool.receive, { accountType: i }).then(res => {
                    if (res.code == 0) {
                        this.$message.success(this.$t('pool.desc65'))
                        this.getreceive();
                    } else {
                        this.$message.error(res.message)
                    }
                })
            }
        },
        handlerCurrent(val) {
            this.page = val;
            this.getlist();
        },
        getlist() {
            this.$post(this.URL.pool.list, {
                page: this.page,
                pageSize: 6
            }).then(res => {
                if (res.code == 0) {
                    this.list = res.data.list;
                    this.total = parseFloat(res.data.page.count)
                } else {
                    this.$message.error(res.message)
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.pool {
    padding: 116px 0 20px;
    margin: 0 auto;
    background: url('../../public/images/pool/pool-bg.png') no-repeat;
    background-size: 100% 100%;

    .pool-content-h5 {
        display: none;
    }

    .pool-content {
        width: 1240px;
        margin: 0 auto;
        min-height: 590px;
        background: url('../../public/images/pool/content-bg.png') no-repeat;
        background-size: 100% 100%;
        margin-bottom: 32px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        position: relative;

        .title {
            position: absolute;
            left: 0;
            top: 0;
            font-family: 'Nebula';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            color: #FFFFFF;
        }

        .pc-wrap {
            width: 100%;
            height: 100%;

            .pc-item {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                height: auto;

                img {
                    width: 100%;
                    height: auto;
                    cursor: pointer;
                }

                .tips-num {
                    position: absolute;
                    top: 70px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 412px;
                    height: 138px;
                    // width: 309px;
                    // height: 103.5px;
                    background: url('../../public/images/pool/tips-bg.png') no-repeat;
                    background-size: 100% 100%;

                    .name {
                        position: absolute;
                        top: -28px;
                        left: 26px;
                        display: flex;
                        align-items: center;
                        font-family: 'Nebula';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 30px;
                        line-height: 58px;
                        letter-spacing: -2px;
                        text-transform: capitalize;
                        color: #FFFFFF;

                        span {
                            display: block;
                            margin-left: 10px;
                            font-family: 'Bai Jamjuree';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 22px;
                            line-height: 14px;
                            color: #FFFFFF;
                        }
                    }
                }

                .tips-btn {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    justify-content: center;

                    div {
                        flex: 0 0 221px;
                        height: 63px;
                        background: url('../../public/images/planentbtnbg2.png') center no-repeat;
                        background-size: 100%;
                        font-size: 16px;
                        color: #FFFFFF;
                        cursor: pointer;
                        text-align: center;
                        line-height: 63px;
                        font-family: 'Nebula';

                        &:last-child {
                            margin-left: 15px;
                            background: url('../../public/images/planetbtnbg.png') center no-repeat;
                            background-size: 100%;
                            color: #000000;
                        }
                    }
                }

                &.little-box {
                    width: 60px;
                    left: 40px;
                    opacity: 0.25;
                    filter: blur(1px);
                    z-index: 2;
                    transition: all 0.8s ease 0s;

                    .tips-num {
                        display: none;
                    }

                    .tips-btn {
                        display: none;
                    }
                }

                &.left-small-box {
                    width: 250px;
                    left: 120px;
                    opacity: 0.5;
                    filter: blur(1px);
                    z-index: 4;
                    transition: all 0.8s ease 0s;

                    .tips-num {
                        top: 0;
                        width: 137px;
                        height: 79px;

                        .name {
                            height: 58px;
                            display: -webkit-flex;
                            justify-content: left;
                            /*水平居中*/
                            align-items: center;
                            /*垂直居中*/
                            -webkit-align-items: center;
                            font-size: 16px;
                            line-height: inherit;
                        }

                        span {
                            display: none;
                        }
                    }

                    .tips-btn {
                        display: none;
                    }
                }

                &.big-box {
                    width: 534px;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 3;
                    transition: all 0.8s ease 0s;
                }

                &.right-small-box {
                    width: 250px;
                    left: 870px;
                    opacity: 0.5;
                    filter: blur(1px);
                    z-index: 4;
                    transition: all 0.8s ease 0s;

                    .tips-num {
                        top: 0;
                        width: 137px;
                        height: 79px;

                        .name {
                            height: 58px;
                            display: -webkit-flex;
                            justify-content: left;
                            /*水平居中*/
                            align-items: center;
                            /*垂直居中*/
                            -webkit-align-items: center;
                            font-size: 16px;
                            line-height: inherit;
                        }

                        span {
                            display: none;
                        }
                    }

                    .tips-btn {
                        display: none;
                    }
                }

                &.other-box {
                    opacity: 0;
                    width: 0;
                    z-index: 1;

                    .tips-num {
                        top: 0;
                        width: 137px;
                        height: 79px;

                        .name {
                            height: 58px;
                            display: -webkit-flex;
                            justify-content: left;
                            /*水平居中*/
                            align-items: center;
                            /*垂直居中*/
                            -webkit-align-items: center;
                            font-size: 16px;
                            line-height: inherit;
                        }

                        span {
                            display: none;
                        }
                    }

                    .tips-btn {
                        display: none;
                    }
                }
            }
        }

        .pc-page {
            position: absolute;
            right: 27px;
            bottom: 30px;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .pc-page-item {
                width: 7px;
                height: 16px;
                margin: 0 4px;
                background: #fff;
                opacity: 0.4;

                &.on {
                    opacity: 1;
                    height: 23px;
                }
            }
        }
    }

    .pool-statistics {
        margin: 0 auto;
        width: 1241.5px;
        height: 187px;
        background: url('../../public/images/pool/pool-statistics-bg.png') no-repeat;
        background-size: 100% 100%;
        position: relative;

        .statistics-left {
            flex: 0 0 263px;
            height: 187px;
            margin-left: 40px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .left-title {
                display: flex;
                align-items: center;
                font-family: 'Bai Jamjuree';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 20px;
                color: #FFFFFF;
                margin-bottom: 11px;

                img {
                    flex: 0 0 13px;
                    width: 13px;
                    height: 13px;
                    margin: 0 4px 0 0;
                }
            }

            .left-total {
                font-family: 'Bai Jamjuree';
                font-style: normal;
                font-weight: 500;
                font-size: 34px;
                line-height: 34px;
                text-align: left;
                color: #FFFFFF;
                margin-bottom: 22px;
            }

            .left-info {
                position: relative;
                display: flex;


                .left-info-name {
                    position: relative;

                    &:last-child {
                        margin-left: 30px;
                        padding-left: 30px;

                        &::before {
                            display: block;
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 1px;
                            height: 28px;
                            background: rgba(255, 255, 255, 0.4);
                        }
                    }

                    div {
                        font-family: 'Bai Jamjuree';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 13px;
                        text-transform: capitalize;
                        color: #A8A8A8;
                        white-space: nowrap;
                        margin-bottom: 10px;
                    }

                    p {
                        font-family: 'Bai Jamjuree';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 16px;
                        color: #FFFFFF;
                        white-space: nowrap;
                    }
                }
            }
        }

        .statistics-draw-out {
            display: flex;
            flex: 1;
            margin-right: 10px;
            justify-content: flex-end;
        }

        .statistics-draw {
            // position: absolute;
            // top: 50%;
            // transform: translateY(-50%);
            flex: 0 0 212px;
            width: 170px;
            height: 168px;
            display: flex;
            margin-top: 40px;
            flex-direction: column;
            margin-left: 8px;

            &:first-child {
                margin-left: 0;
            }

            .draw-title {
                margin-bottom: 10px;
                font-family: 'Bai Jamjuree';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 20px;
                color: #FFFFFF;
                white-space: nowrap;
                width: 100%;
                text-align: center;
            }

            .draw-price {
                font-family: 'Bai Jamjuree';
                font-style: normal;
                font-weight: 500;
                font-size: 34px;
                line-height: 34px;
                color: #FFFFFF;
                white-space: nowrap;
                margin-bottom: 18px;
                width: 100%;
                text-align: center;
            }

            .draw-btn {
                width: 139.05px;
                height: 34.16px;
                background: url('../../public/images/pool/bt.png') no-repeat;
                background-size: 100% 100%;
                font-size: 14px;
                color: #111111;
                cursor: pointer;
                margin: 0 auto;
                font-family: 'Nebula';
            }

            .grey {
                background: #444444;
            }

            &:nth-child(1) {
                // padding: 0 37px 0 0;
                // right: 544px;
                //align-items: flex-start;
                // .draw-title,
                // .draw-price {
                //     width: 100%;
                //     text-align: right;
                // }
            }

            &:nth-child(2) {
                // padding: 0 0 0 37px;
                // width: 231px;
                // right: 315px;
            }

            &:nth-child(3) {
                // padding: 0 0 0 44px;
                // width: 289px;
                // right: 18px;
            }
        }
    }

    .pool-con-name {
        width: 1240px;
        margin: 0 auto;
        padding: 32px 0 24px;
        font-size: 24px;
        color: #FFFFFF;
        line-height: 24px;

        p {
            margin-left: 25px;
            font-size: 13px;
            color: #A8A8A8;
        }
    }

    .pool-con-desc {
        font-size: 13px;
        color: #A8A8A8;
    }

    .pool-con {
        width: 1240px;
        margin: 0 auto;
        padding: 0;
        flex-wrap: wrap;

        .pool-con-list {
            flex: 0 0 404px;
            padding: 32px 0;
            margin: 0 14px 14px 0;
            background: #1e1e1e;
            cursor: pointer;

            &:nth-child(3n) {
                margin-right: 0;
            }

            .list-top {
                padding: 0 32px;

                img {
                    flex: 0 0 64px;
                    width: 64px;
                    height: 64px;
                    margin-right: 14px;
                    object-fit: cover;
                }

                .list-top-con {
                    flex: 1;
                    overflow: hidden;
                    font-size: 13px;
                    color: #A8A8A8;

                    div {
                        margin: 8px 0 10px;
                        font-size: 16px;
                        color: #FFFFFF;
                    }
                }
            }

            .list-num {
                padding: 32px 32px 0;
                justify-content: space-between;

                .num-info {
                    font-size: 13px;
                    color: #A8A8A8;

                    p {
                        margin-top: 10px;
                        font-size: 16px;
                        color: #FFFFFF;
                    }
                }
            }
        }
    }

    .planet-page {
        width: 1240px;
        margin: 0 auto;
        padding: 0 0 40px 0;

        ::v-deep .el-pagination {
            button {
                background-color: rgba(255, 255, 255, 0);
                color: #666666;
                font-size: 14px;
            }

            .el-pager {
                li {
                    background: rgba(255, 255, 255, 0);
                    font-size: 14px;
                    color: #666666;
                }

                .is-active {
                    background: #FFE279 !important;
                    border-radius: 4px !important;
                    color: #000000 !important;
                }
            }

        }
    }
}

#footer {
    display: none;
}

@media (max-width:1200px) {
    #footer {
        display: block;
    }

    .pool {
        padding: 101px 7px 0;
        width: 100%;
        overflow: hidden;

        .pool-content {
            display: none;
        }

        .pool-content-h5 {
            display: block;

            .pch-in {
                margin: 0 auto 64px;
                position: relative;
                width: 100%;
                height: 407px;
                background: url('../../public/images/pool/h5/content-bg.png') no-repeat;
                background-size: 100% 100%;
                border: none;

                .title {
                    position: absolute;
                    font-family: 'Nebula';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 14px;
                    color: #FFFFFF;
                }

                :deep(.van-swipe) {
                    width: 100%;
                    height: 100%;
                    overflow: inherit;
                }

                :deep(.van-swipe-item) {
                    display: flex;
                    align-items: center;
                }

                :deep(.van-swipe-item img) {
                    width: 340px;
                }

                :deep(.van-swipe__indicators) {
                    position: absolute;
                    bottom: -32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .van-swipe__indicator {
                        width: 7px;
                        height: 16px;
                        margin: 0 4px;
                        background: #fff;
                        opacity: 0.4;
                        border-radius: 0;
                    }

                    .van-swipe__indicator--active {
                        opacity: 1;
                        height: 23px;
                    }
                }
            }

            .h5-btn {
                margin-bottom: 22px;
                padding: 0 9px;
                justify-content: space-between;

                div {
                    flex: 0 0 48%;
                    height: 54px;
                    font-size: 12px;
                    color: #fff;
                    background: url('../../public/images/pool/btn1.png') center no-repeat;
                    background-size: 100% 100%;
                    font-family: 'Nebula';

                    &:last-child {
                        color: #000;
                        background: url('../../public/images/pool/btn2.png') center no-repeat;
                        background-size: 100% 100%;
                    }
                }
            }
        }

        .pool-statistics {
            width: 100%;
            height: 581px;
            background: none;
            display: block;
            margin-bottom: 20px;
            flex-wrap: wrap;
            background: url('../../public/images/pool/poolbg.png') center no-repeat;
            background-size: 100% 100%;

            .statistics-left {
                margin: 0;
                flex: 0 0 100%;
                height: initial;
                padding-top: 26px;
                text-align: center;

                .left-title {
                    padding-left: 38px;
                    font-size: 18px;
                    margin-bottom: 8px;
                }

                .left-total {
                    padding: 0 38px;
                    font-size: 30px;
                    margin-bottom: 2px;
                }

                .left-info {
                    padding: 0 38px;
                    justify-content: flex-start;

                    &::before {
                        display: none;
                    }

                    .left-info-name {
                        div {
                            font-size: 10px;
                            margin-bottom: 2px;
                            line-height: 13px;
                        }

                        p {
                            font-size: 14px;
                            line-height: 16px;
                        }
                    }
                }
            }

            .statistics-draw-out {
                flex-wrap: wrap;
                flex: 0 0 100%;
                margin-right: 0;
            }

            .statistics-draw {
                position: relative;
                flex: 0 0 100% !important;
                width: 100%;
                flex-direction: initial;
                height: inherit;
                display: block;
                margin-top: 0;
                margin-bottom: 47px;

                &:first-child {
                    margin-top: 40px;
                }

                .draw-title {
                    padding-left: 38px;
                    font-size: 15px;
                    text-align: left;
                }

                .draw-price {
                    margin-bottom: 0;
                    padding-left: 38px;
                    font-size: 30px;
                    text-align: left;
                }

                .draw-btn {
                    position: absolute;
                    top: 50%;
                    right: 16px;
                    transform: translateY(-50%);
                }
            }


        }
    }
}

@media (max-width:767px) {
    .pool {
        width: auto;
    }
}</style>